exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conheca-shelfin-js": () => import("./../../../src/pages/conheca-shelfin.js" /* webpackChunkName: "component---src-pages-conheca-shelfin-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-conteudo-js": () => import("./../../../src/pages/conteudo.js" /* webpackChunkName: "component---src-pages-conteudo-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-enviado-js": () => import("./../../../src/pages/enviado.js" /* webpackChunkName: "component---src-pages-enviado-js" */),
  "component---src-pages-headhunter-js": () => import("./../../../src/pages/headhunter.js" /* webpackChunkName: "component---src-pages-headhunter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-recrutamento-js": () => import("./../../../src/pages/recrutamento.js" /* webpackChunkName: "component---src-pages-recrutamento-js" */),
  "component---src-pages-sucesso-enviado-js": () => import("./../../../src/pages/sucesso-enviado.js" /* webpackChunkName: "component---src-pages-sucesso-enviado-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */),
  "component---src-pages-use-shelfin-js": () => import("./../../../src/pages/use-shelfin.js" /* webpackChunkName: "component---src-pages-use-shelfin-js" */),
  "component---src-pages-use-trilha-js": () => import("./../../../src/pages/use-trilha.js" /* webpackChunkName: "component---src-pages-use-trilha-js" */)
}

